<template>
  <div id="admin" class="container">
    <template v-if="$apollo.loading">
      <loading />
    </template>
    <template v-else>
      <ValidationObserver ref="observer" v-slot="{ passes }">
        <div class="columns">
          <div class="column is-6 is-offset-3">
            <form @submit.prevent="passes(submit)">
              <SdInput
                type="email"
                rules="required|email"
                label="Email"
                v-model="admin.email"
                placeholder="Enter a email"
              />

              <SdInput
                type="text"
                rules="required|min:5|max:30"
                label="Name"
                v-model="admin.name"
                placeholder="Enter a name"
              />

              <SdInput
                type="text"
                :rules="{
                  required: true,
                  length: 13,
                  regex: /234\d{10}$/,
                }"
                label="Phone"
                v-model="admin.phone"
                placeholder="Enter a phone"
              />
              <button
                type="submit"
                class="button btn-120 is-primary is-capitalized is-pulled-right mt-4"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import SdInput from '@/components/SdInput'
import { ValidationObserver } from 'vee-validate'
import { crudNotification } from '@/assets/js/notification'

export default {
  name: 'admin',
  data() {
    return {
      schoolId: null,
      termId: null,
      pageTitle: 'Admin',
      skipQuery: true,
      admin: {
        id: null,
        userId: null,
        termId: null,
        name: '',
        email: '',
        phone: '',
      },
    }
  },
  apollo: {
    admin: {
      query: gql`
        query admin($id: ID!) {
          admin(id: $id) {
            id
            name
            user {
              id
              email
            }
            phone
          }
        }
      `,
      variables() {
        return { id: this.$route.params.id }
      },
      skip() {
        return this.skipQuery
      },
    },
  },
  watch: {
    admin(data) {
      this.admin.email = data.user.email
    },
  },
  methods: {
    submit() {
      if (this.$route.params.id === 'new') {
        // Add
        this.$apollo
          .mutate({
            mutation: gql`
              mutation CreateAdmin(
                $termId: Int!
                $name: String!
                $email: String!
                $phone: String!
              ) {
                createAdmin(
                  input: {
                    termId: $termId
                    name: $name
                    email: $email
                    phone: $phone
                  }
                ) {
                  admin {
                    id
                  }
                  errors
                }
              }
            `,
            // Parameters
            variables: {
              termId: this.termId,
              name: this.admin.name.trim(),
              email: this.admin.email.trim(),
              phone: this.admin.phone,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.createAdmin.errors,
              'Successfully created.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/admins`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      } else {
        this.$apollo
          .mutate({
            mutation: gql`
              mutation UpdateAdmin(
                $id: Int!
                $name: String!
                $email: String!
                $phone: String!
              ) {
                updateAdmin(
                  input: { id: $id, name: $name, email: $email, phone: $phone }
                ) {
                  admin {
                    id
                    name
                    phone
                  }
                  errors
                }
              }
            `,
            variables: {
              id: parseInt(this.admin.id),
              name: this.admin.name.trim(),
              email: this.admin.email.trim(),
              phone: this.admin.phone,
            },
          })
          .then((response) => {
            crudNotification(
              response.data.updateAdmin.errors,
              'Successfully updated.'
            ).then(() => {
              this.$router.push(
                `/school/${this.$route.params.school_id}/admins`
              )
            })
          })
          .catch((error) => {
            console.error(error)
          })
      }
    },
  },
  components: {
    SdInput,
    ValidationObserver,
  },
  mounted() {
    if (this.$route.params.id !== 'new') this.skipQuery = false
    this.schoolId = parseInt(this.$route.params.school_id)
    this.termId = parseInt(this.$store.getters.getTerm.id)
    this.$store.commit('setPageTitle', this.pageTitle)
    this.$store.commit('setSubMenus', [
      {
        name: 'Admins',
        route: `/school/${this.schoolId}/admins`,
      },
    ])
  },
}
</script>
